import { Tooltip } from '@mui/material';
import { Skeleton } from 'components';
import { useApi } from 'contexts';
import { useExportContractViewLazyQuery } from 'gql';
import toast from 'react-hot-toast';
import { useLocalStorage } from 'usehooks-ts';

import { Button } from './Button';
import { Download } from './svg';

export const ExportCsvComponent = () => {
  const { sessionKey, account } = useApi();
  const address = account?.address;
  const [lastExported, setLastExported] = useLocalStorage<string | null>(
    `lastExported-${address}`,
    null
  );
  const lastExportedDate = lastExported ? new Date(lastExported) : null;
  const disableExport =
    lastExportedDate !== null &&
    new Date().getTime() - lastExportedDate.getTime() < 12 * 60 * 60 * 1000;

  const downloadCSVFile = (base64Data: string, fileName: string) => {
    if (!base64Data) return;
    const csvContent = atob(base64Data);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const [exportContractView, { loading }] = useExportContractViewLazyQuery({
    onCompleted: (data) => {
      const exportData = data?.exportContractView;
      if (exportData && exportData.success) {
        if (exportData.view) {
          downloadCSVFile(exportData.view, `views-${address}.csv`);
        }
        if (exportData.contract) {
          downloadCSVFile(exportData.contract, `smartContracts-${address}.csv`);
        }
        setLastExported(new Date().toISOString());
      } else {
        if (exportData?.error?.includes('12')) {
          setLastExported(new Date().toISOString());
        }
        toast.error(exportData?.error || 'Failed to export the CSV! Please try again');
      }
    },
    onError: (error) => {
      console.error('Export error:', error);
      toast.error('Failed to export the CSV! Please try again');
    },
  });

  const onExport = () => {
    if (!account?.address || !sessionKey) {
      toast.error('Account or session key missing');
      return;
    }
    exportContractView({
      variables: {
        address: account.address,
        sessionKey,
      },
    });
  };

  return (
    <div className="flex flex-row sm:w-[unset] w-full justify-between items-center gap-4 sm:order-2 order-1">
      <Skeleton.Loader isDarkTheme className="w-[120px] h-10 !rounded-full">
        <Tooltip
          title={
            disableExport
              ? 'Please wait 12 hours before exporting again.'
              : 'Click here to export and back up your listed smart contracts and created views.'
          }
          arrow
          placement="top"
          classes={{ tooltip: '!min-w-[200px] justify-center !text-sm' }}
        >
          <Button variant="plain" onClick={onExport} isLoading={loading} isDisabled={disableExport}>
            <Download className="mr-2" />
            <span className="text-lg font-light text-white">Export</span>
          </Button>
        </Tooltip>
      </Skeleton.Loader>
    </div>
  );
};

export default ExportCsvComponent;
