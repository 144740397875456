import { CustomInfoIcon, Input } from 'components';
import { Props } from 'components/EditMetadata/types';
import { InputTags } from 'components/InputTags';
import { Setter } from 'types';
import { classes } from 'utils';

interface AddUseCaseProps {
  labels: Props['labels'];
  isError: boolean;
  alreadyExistError: boolean;
  name: string;
  setName: Setter<string>;
  formError: {
    tag: { isTouched: boolean; isError: boolean };
    description: { isTouched: boolean; isError: boolean };
    name: { isTouched: boolean; isError: boolean };
    useCase: { isTouched: boolean; isError: boolean };
  };
  setFormError: any;
  setIsError: Setter<boolean>;
  editTags: any;
  description: string;
  setDescription: Setter<string>;
}

const AddUseCase = ({
  labels,
  isError,
  alreadyExistError,
  name,
  setName,
  formError,
  setFormError,
  setIsError,
  editTags,
  description,
  setDescription,
}: AddUseCaseProps) => {
  return (
    <div className="py-6 sm:px-6 px-4 border border-x-0 border-solid border-[#1C1C1C]">
      <div data-cy={`${labels.title}`} className="text-xl">
        {labels.title}
      </div>
      {isError && labels.error && !alreadyExistError && (
        <div className="text-sm flex items-center gap-1.5 mt-4 text-[#FF5C5C]">
          <CustomInfoIcon stroke="#FF5C5C" />
          {labels.error}
        </div>
      )}
      <div className="flex-1 mt-4">
        <label htmlFor="view-name" className="text-white text-sm flex flex-row gap-1 items-center">
          {labels.nameLabel} *
        </label>
        <Input
          classNames={{ base: 'mt-2 h-12', input: 'pl-6' }}
          id={'view-name'}
          placeholder={labels.namePlaceholder}
          value={name}
          filter={/^[^a-zA-Z]|[^a-zA-Z0-9_]/g}
          onChange={(text) => {
            if (text.length < 31) setName(text);
            if (formError.name.isError)
              setFormError((prev: any) => ({
                ...prev,
                name: {
                  isError: false,
                  isTouched: true,
                },
              }));
            if (alreadyExistError) {
              setIsError(false);
            }
          }}
          isDarkTheme
          isError={
            (formError.name.isError && formError.name.isTouched) ||
            (isError && alreadyExistError && formError.name.isTouched)
          }
        />
        {isError && alreadyExistError && formError.name.isTouched ? (
          <div data-cy="same-identifier-error-message" className="text-[#FF5C5C] text-xs mt-2">
            {labels.error}
          </div>
        ) : (
          formError.name.isError &&
          formError.name.isTouched && (
            <div className="text-[#FF5C5C] text-xs mt-2">
              View name must be at least 6 characters long.
            </div>
          )
        )}

        <div className="mb-4 mt-4">
          <label htmlFor="view-name" className={classes('text-white text-sm')}>
            <div className="flex flex-row items-center gap-1">
              {labels.tagLabel || 'Please Type Tags'} *
            </div>
          </label>
          <InputTags
            classNames={{
              base: 'h-12 !mt-2 pl-0',
              options: '-left-0',
              input: 'md:pl-6 pl-3',
              tags: 'max-h-[58px] h-fit min-h-8 overflow-y-scroll scrollbar-white',
              tag: 'normal-case',
            }}
            {...editTags}
            placeholder={labels.tagPlaceholder}
            withCreate
            onFocus={() => {
              editTags.setIsFocused(true);
            }}
            limitOverflowMessage="You can only add up to 10 tags."
            limit={10}
          />
          {formError.tag.isTouched && formError.tag.isError && (
            <div data-cy="include-tag-error-message" className="text-[#FF6666] text-xs ">
              Please include at least one tag other than chain tags.
            </div>
          )}
        </div>
        <div>
          <label htmlFor="view-name" className={classes('text-white text-sm')}>
            <div className="flex flex-row gap-1 items-center">{labels.descriptionLabel} *</div>
          </label>
          <Input
            type="textarea"
            className="relative mt-2 px-1.5 pl-6 py-[13px] h-[87px] rounded-2xl resize-none items-start pb-[22px]"
            dataCy="input-description-view-builder"
            classNames={{
              input: 'dark resize-none scrollbar-white p-0 h-full break-words',
            }}
            placeholder={labels.descriptionPlaceholder}
            value={description}
            isError={formError.description.isTouched && formError.description.isError}
            onChange={(text) => {
              if (text.length <= 273) setDescription(text);
              if (formError.description.isError) {
                setFormError((prev: any) => ({
                  ...prev,
                  description: { isError: false, isTouched: false },
                }));
              }
            }}
            isDarkTheme
          >
            <div
              className={
                'absolute right-[32px] bottom-[1px] w-[23px] h-4 text-xs leading-4 text-[#666666] '
              }
            >
              {description.trim().length}/273
            </div>
          </Input>
          {formError.description.isTouched && formError.description.isError && (
            <div data-cy="description-error" className="text-[#FF6666] mt-2 text-xs">
              Please ensure your input contains a minimum of 20 characters.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddUseCase;
