import { useEffect, useState } from 'react';

import { useApi, useSmartContract } from 'contexts';
import { useDataDesignQuery, useSmartContractFunctionsQuery } from 'gql';
import { useTabs } from 'hooks';
import { useListSmartContract } from 'hooks/useListSmartContracts';
import { useLocation, useParams } from 'react-router-dom';
import { classes } from 'utils';

import { Contract } from './Components/Contract';

const useCollectionsContract = () => {
  const { resource: smartContract } = useSmartContract();
  const { id: address } = useParams();
  const location = useLocation();
  const contractNetwork = location.pathname.split('/')[3].split('-')[0];
  const { data: functionData } = useSmartContractFunctionsQuery({
    variables: { address: address as string, network: contractNetwork },
    fetchPolicy: 'cache-and-network',
  });

  const [isAddFunctionOpen, setIsAddFunctionOpen] = useState(false);

  const { account } = useApi();

  const {
    mergeContractResult,
    isMergeContractError,
    smartContractAddress,
    setSmartContractDescription,
    setSmartContractIdentifier,
    setSmartContractAddress,
    setSelectedChain,
    setMergeContractResult,
    setIsMergeContractError,
    clearDryRunContractResult,
  } = useListSmartContract();

  useEffect(() => {
    if (isAddFunctionOpen) {
      setSmartContractDescription(smartContract?.description || '');
      setSmartContractIdentifier(smartContract?.name || '');
      setSmartContractAddress(smartContract?.address || '');
      setSelectedChain({
        slug: smartContract.networks[0] as string,
        name: smartContract.networks[0] as string,
      });
    } else {
      setSmartContractDescription('');
      setSmartContractIdentifier('');
      setSmartContractAddress('');
      setSelectedChain(undefined);
      setIsMergeContractError(false);
      setMergeContractResult(undefined);
    }
  }, [isAddFunctionOpen, smartContract]);

  useEffect(() => {
    if (mergeContractResult && !isMergeContractError) {
      setSmartContractAddress('');
    }
  }, [mergeContractResult, isMergeContractError]);

  const { id, network } = useParams();
  const { data } = useDataDesignQuery({
    variables: {
      filters: {
        smartContractAddress: id,
        network,
      },
      limit: 3,
      page: 1,
      sort: null,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { selectedTab, setSelectedTab, tabPanels, tabSelector, tabs } = useTabs(
    [
      {
        alternateLabel: data ? `Views (${data?.dataDesign?.count})` : 'Views',
        child: <Contract type="view" />,
        label: data ? `Views (${data?.dataDesign?.count})` : 'Views',
        value: 'views',
      },
      {
        alternateLabel: functionData
          ? `Function (${functionData?.smartContractFunctions?.length})`
          : 'Functions',
        child: <Contract type="function" />,
        label: functionData
          ? `Function (${functionData?.smartContractFunctions?.length})`
          : 'Functions',
        value: 'functions',
      },
      {
        alternateLabel: 'Contract Metadata',
        child: <Contract type="contract" />,
        label: 'Contract',
        value: 'contract',
      },
    ],
    0,
    {
      classNames: {
        base: 'card text-xs whitespace-nowrap w-full !bg-transparent rounded-[32px] !border-[#ffffff33] p-1 max-h-[40px]',
        tab: (_) => classes(' text-white text-sm px-2.5', _ && 'bg-[#1B1B1B] rounded-[32px] '),
      },
    }
  );

  const CloseModal = () => setIsAddFunctionOpen(false);

  return {
    selectedTab,
    setSelectedTab,
    tabSelector,
    account,
    setIsAddFunctionOpen,
    tabs,
    tabPanels,
    isAddFunctionOpen,
    clearDryRunContractResult,
    mergeContractResult,
    isMergeContractError,
    smartContractAddress,
    smartContract,
    CloseModal,
  };
};

export default useCollectionsContract;
