import { useCallback, useEffect, useMemo, useState } from 'react';

import init, { build_ssk, encode_ssk } from '@analog-labs/timegraph-wasm/web/lib';
import { Download } from 'components';
import { useApi } from 'contexts';
import { useSearchLibraryCountLazyQuery } from 'gql';
import { useBreadCrumbsChild } from 'hooks/useBreadCrumbs';
import fileDownload from 'js-file-download';
import { useCookies } from 'react-cookie';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { hexToU8a, notify, stringToHex, web3FromSource } from 'utils';

export const useMyProfile = (profile: any, tabCtx: any) => {
  useBreadCrumbsChild({ page: 'MyProfile' }, [{ page: 'Library' }]);
  const [{ account: accountCookie }] = useCookies(['account']);
  if (!accountCookie?.address) {
    redirect('/');
  }

  const {
    account,
    isReady,
    chainProps: { tokenDecimals },
    sessionKey,
  } = useApi();

  const [isOpen, setIsOpen] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [sortBy, setSortBy] = useState<'testnet' | 'mainnet' | 'all'>('all');
  const handleClick = useCallback((type: boolean) => {
    setIsWithdraw(type);
    setIsOpen(true);
  }, []);

  const isLoading = useMemo(
    () => !isReady || !profile.myProfile || !profile.myBalance || !profile.accountBalance,
    [isReady, profile]
  );
  const location = useLocation();
  const isPrivate = useMemo(() => location.pathname.includes('profile'), [location.pathname]);
  const { id } = useParams();

  const [executeSearchLibraryCount, { data: countData, loading: isCountDataLoading }] =
    useSearchLibraryCountLazyQuery();

    useEffect(() => {
      executeSearchLibraryCount({
        variables: {
          filters: {
            publisherAddress: isPrivate ? accountCookie?.address : id,
          },
        },
        fetchPolicy: 'cache-and-network',
      });
    }, [isPrivate, accountCookie, id, executeSearchLibraryCount]);

  const refreshSearchQuery = () => {
    executeSearchLibraryCount({
      variables: {
        filters: {
          publisherAddress: isPrivate ? accountCookie?.address : id,
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  };

  useEffect(() => {
    const isModalOpen = sessionStorage.getItem('isModalOpen') === 'true';
    setIsOpen(isModalOpen);
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('isModalOpen');
  }, [isOpen]);

  const clickHandler = useCallback(async () => {
    if (account) {
      await init();
      const injector = await web3FromSource(account.meta.source);
      const signRaw = injector?.signer?.signRaw;
      if (signRaw) {
        const sskData = encode_ssk({
          ns: 0,
          key: account.address,
          user_id: 1,
          expiration: 0,
        });

        const { signature } = await signRaw({
          address: account.address,
          data: stringToHex(sskData),
          type: 'bytes',
        });

        const sessionKey = build_ssk(sskData, hexToU8a(signature));
        fileDownload(sessionKey, 'sessionkey.txt');
        notify('Session key downloaded', Download);
      }
    }
  }, [account]);
  return {
    account,
    isLoading,
    handleClick,
    tokenDecimals,
    sessionKey,
    clickHandler,
    countData,
    refreshSearchQuery,
    isCountDataLoading,
    sortBy,
    isOpen,
    isWithdraw,
    setIsOpen,
  };
};

export default useMyProfile;
