import { useCallback, useEffect, useMemo, useState } from 'react';

import AddUseCase from './components/CreateNewViewModels/AddUseCase';
import CreateNewUseCase from './components/CreateNewViewModels/CreateNewUseCase';
import CreateNewView from './components/CreateNewViewModels/CreateNewView';
import { Props } from './types';

const useEditMetadata = ({
  labels,
  isOpen,
  name,
  setName,
  description,
  setDescription,
  useCases,
  setUseCases,
  useCasesOption,
  setUseCasesOption,
  submitForGame,
  setSubmitForGame,
  ...editTags
}: Props) => {
  const [step, setStep] = useState(1);
  const [newUsecase, setNewUsecase] = useState('');
  const [searchString, setSearchString] = useState('');
  const [isError, setIsError] = useState(false);

  const alreadyExistError = String(labels.error).includes('already');

  const [formError, setFormError] = useState({
    tag: {
      isTouched: false,
      isError: false,
    },
    description: {
      isTouched: false,
      isError: false,
    },
    name: {
      isTouched: false,
      isError: false,
    },
    useCase: {
      isTouched: false,
      isError: false,
    },
  });

  const filteredUsecases = useMemo(
    () =>
      useCasesOption.filter((item) => item?.toLowerCase()?.includes(searchString?.toLowerCase())),
    [useCasesOption, searchString]
  );

  const handleSelect = useCallback(
    (name: string) => {
      if (useCases.includes(name)) {
        setUseCases((prev) => prev.filter((item) => item !== name));
      } else if (useCases.length < 10) {
        setUseCases((prev) => [...prev, name]);
      }
    },
    [useCases]
  );

  useEffect(() => {
    if (labels.error) {
      setIsError(true);
      setStep(1);
    }
  }, [labels.error]);

  useEffect(() => {
    editTags.resetTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, editTags.resetTags]);

  useEffect(() => {
    newUsecase && setNewUsecase('');
  }, [step]);

  useEffect(() => {
    if (
      editTags.tags.filter((el) => !editTags.chainTags?.some(({ slug }) => slug == el.slug))
        .length > 0
    ) {
      setFormError((prev) => ({
        ...prev,
        tag: {
          isError: false,
          isTouched: false,
        },
      }));
    }
  }, [editTags.tags]);

  useEffect(() => {
    if (useCases.length) {
      setFormError((prev) => ({
        ...prev,
        useCase: {
          isError: false,
          isTouched: true,
        },
      }));
    }
  }, [useCases]);

  const formContent = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <AddUseCase
            labels={labels}
            isError={isError}
            alreadyExistError={alreadyExistError}
            name={name}
            setName={setName}
            formError={formError}
            setFormError={setFormError}
            setIsError={setIsError}
            editTags={editTags}
            description={description}
            setDescription={setDescription}
          />
        );
      case 2:
        return (
          <CreateNewView
            filteredUsecases={filteredUsecases}
            formError={formError}
            handleSelect={handleSelect}
            searchString={searchString}
            setSearchString={setSearchString}
            setStep={setStep}
            setSubmitForGame={setSubmitForGame}
            submitForGame={submitForGame}
            useCases={useCases}
          />
        );
      case 3:
        return <CreateNewUseCase newUsecase={newUsecase} setNewUsecase={setNewUsecase} />;
    }
  }, [step, { ...labels }, name, { ...editTags }, description, useCasesOption, formError]);

  const isInputsValid = useCallback(() => {
    const isValidTags =
      editTags.tags.filter((el) => !editTags.chainTags?.some(({ slug }) => slug == el.slug))
        .length > 0;
    setFormError((prev) => ({
      ...prev,
      description: {
        isError: description.trim().length < 20,
        isTouched: true,
      },
      tag: {
        isError: !isValidTags,
        isTouched: true,
      },
      name: {
        isError: name.trim().length < 6,
        isTouched: true,
      },
    }));
    return description.trim().length >= 20 && isValidTags && name.length > 5;
  }, [description, editTags.tags, editTags.chainTags, name]);

  const handleSubmitActions = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      switch (step) {
        case 1:
          if (isInputsValid()) {
            setStep((prev) => prev + 1);
          }
          break;
        case 3:
          newUsecase.length &&
            setUseCasesOption((prev) => {
              if (!prev.includes(newUsecase)) {
                return [newUsecase, ...prev];
              } else {
                return [...prev];
              }
            });
          setStep((prev) => prev - 1);
          break;
      }
    },
    [step, newUsecase, isInputsValid]
  );

  useEffect(() => {
    if (!isOpen) {
      setStep(1);
      setIsError(false);
      setNewUsecase('');
      setSearchString('');
    }
  }, [isOpen]);

  return { step, setFormError, formContent, setStep, handleSubmitActions };
};

export default useEditMetadata;
