import { useCallback, useEffect, useState } from 'react';

import { Button, CustomInfoIcon, Input, Tag } from 'components';
// import { ListBoxOptionType } from 'components/ListBox';
import { InputTags } from 'components/InputTags';
import { useApi } from 'contexts';
import { useFormField } from 'hooks/useFormField';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { classes } from 'utils';

import { useListSmartContract } from '../../hooks/useListSmartContracts';

import useListNewSmartContract from './useListNewSmartContract';

type ListNewSmartContractProps = {
  onNext: () => void;
};

// const instanceOption: ListBoxOptionType[] = [
//   // { id: 1, name: 'Testnet', value: 'testnet', unavailable: true },
//   { id: 2, name: 'Mainnet', value: 'mainnet' },
// ];

export const ListNewSmartContract = ({ onNext }: ListNewSmartContractProps) => {
  const {
    smartContractAddress,
    dryRunContract,
    selectedChain,
    handleSmartContractInputChange,
    smartContractIdentifier,
    setSmartContractIdentifier,
    smartContractDescription,
    setSmartContractDescription,
    smartContractInstance: selectedInstance,
    setIsMergeContractError,
    editTags,
    isSmartContractAlreadyListed,
    setIsError,
    tags,
    mergeContractResult,
    setMergeContractResult,
  } = useListSmartContract();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isValid, isTouched, setSmartContractValue, setIsTouched] = useFormField(
    '',
    (s) => s.length > 0 && /^[a-fA-F0-9]{40}$/.test(s)
  );
  const identifierRegex = /^[a-zA-Z0-9][a-zA-Z0-9_]*$/;
  const { sessionKey, account } = useApi();

  const { existErrorMsg, redirectAddress } = useListNewSmartContract({
    smartContractAddress,
    isSmartContractAlreadyListed,
    mergeContractResult,
  });

  const [UnsyncError, setUnsyncError] = useState<string | undefined>();
  const identifierExistsError = existErrorMsg?.includes('already exist');

  const validateSmartContract = () => {
    setIsTouched(true);
    return setSmartContractValue(smartContractAddress);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [formError, setFormError] = useState({
    description: {
      isTouched: false,
      isError: false,
    },
    tags: {
      isTouched: false,
      isError: false,
    },
    chain: {
      isTouched: false,
      isError: false,
    },
    identifier: {
      isTouched: false,
      isError: false,
    },
    address: {
      isTouched: false,
      isError: false,
    },
  });

  const validateInputs = useCallback(() => {
    if (!isSmartContractAlreadyListed) {
      setFormError({
        chain: {
          isError: !selectedChain,
          isTouched: true,
        },
        identifier: {
          isTouched: true,
          isError:
            !smartContractIdentifier ||
            Boolean(identifierExistsError) ||
            smartContractIdentifier.trim().length < 4 ||
            !identifierRegex.test(smartContractIdentifier),
        },
        description: {
          isTouched: true,
          isError: smartContractDescription.trim().length < 20,
        },
        tags: {
          isError: editTags.tags.length < 2,
          isTouched: true,
        },
        address: {
          isError: smartContractAddress.trim().length !== 40,
          isTouched: true,
        },
      });
    }

    return (
      isSmartContractAlreadyListed ||
      (selectedChain &&
        smartContractIdentifier.trim().length >= 4 &&
        smartContractDescription.trim().length >= 20 &&
        identifierRegex.test(smartContractIdentifier) &&
        editTags.tags.length >= 2)
    );
  }, [
    smartContractIdentifier,
    selectedChain,
    editTags.tags,
    smartContractDescription,
    smartContractAddress,
    isSmartContractAlreadyListed,
  ]);

  useEffect(() => {
    if (selectedChain) {
      setFormError((prev) => ({
        ...prev,
        chain: {
          isError: false,
          isTouched: false,
        },
      }));
    }
    editTags.setTags((prev) => {
      // const tagIndex = prev.findIndex((tag) => tag.slug === 'ethereum' || tag.slug === 'astar');
      const tagIndex = prev.findIndex((el) =>
        editTags.chainTags?.some(({ slug }) => slug == el.slug.split('-')[0])
      );

      if (selectedChain) {
        if (tagIndex !== -1) {
          if (prev[tagIndex].slug === selectedChain.slug) {
            return prev;
          } else {
            const updatedTags = [...prev];
            updatedTags.splice(tagIndex, 1);
            return [selectedChain, ...updatedTags];
          }
        } else {
          return [selectedChain, ...prev];
        }
      } else {
        const updatedTags = [...prev];
        updatedTags.splice(tagIndex, 1);
        return [...updatedTags];
      }
    });
  }, [selectedChain]);

  useEffect(() => {
    if (
      editTags.tags.filter((el) => !editTags.chainTags?.some(({ slug }) => slug == el.slug))
        .length > 0
    ) {
      setFormError((prev) => ({
        ...prev,
        tags: {
          isError: false,
          isTouched: false,
        },
      }));
    }
  }, [editTags.tags]);

  useEffect(() => {
    if (identifierExistsError) {
      setFormError((prev) => ({
        ...prev,
        identifier: {
          isError: true,
          isTouched: true,
        },
      }));
    }
  }, [identifierExistsError]);

  useEffect(() => {
    if (isSmartContractAlreadyListed) {
      setFormError((prev) => ({
        ...prev,
        identifier: {
          isError: false,
          isTouched: false,
        },
        description: {
          isError: false,
          isTouched: false,
        },
      }));
    }
  }, [isSmartContractAlreadyListed]);

  return (
    <>
      <div className="flex-1">
        <div className="flex justify-between">
          <p data-cy="Smart-contract-popup-label" className="text-2xl">
            List a new Smart Contract
          </p>
        </div>
        <div className="">
          <p className="text-sm mt-2 text-[#B2B3B8]">
            {isSmartContractAlreadyListed
              ? ' You are listing a smart contract that is already listed.'
              : 'Insert the smart contract details.'}
          </p>
          {UnsyncError && (
            <div className="mt-[14px] p-[10px] bg-red-400 bg-opacity-5  justify-start items-center gap-[8px] flex rounded-2xl">
              <div className="h-8 w-8 flex items-center justify-center rounded-md bg-[#ff5c5c1a]">
                <CustomInfoIcon />
              </div>
              <div className="flex-1 flex items-center justify-between flex-wrap gap-1">
                <div
                  data-cy="Current listing already exists please check"
                  className="text-red-800 text-xs font-normal leading-tight"
                >
                  {UnsyncError}
                </div>
              </div>
            </div>
          )}
          <div className="flex md:gap-4 gap-[14px] w-full md:flex-row flex-col md:mt-5 mt-[14px] ">
            <div className="flex flex-col relative flex-1">
              <label htmlFor="smart-contract-input" className="text-white text-sm">
                Smart Contract Address *
              </label>
              <Input
                dataCy="input-smart-contract-address"
                tabIndex={0}
                classNames={{
                  base: 'dark mt-3 placeholder:text-[#ffffff7a] text-sm p-3 border rounded-3xl bg-[#0a0a0a] text-white border-[#1F1F1F] shadow-[0px_8px_10px_0px_#000,0px_-2px_52px_0px_rgba(200, 200, 200, 0.06)_inset]',
                  input: classes('text-white pl-0', !smartContractAddress && 'pl-2'),
                }}
                id="smart-contract-input"
                type="text"
                isError={
                  (isTouched && !isValid) ||
                  (formError.address.isError && formError.address.isTouched)
                }
                placeholder="e.x. 484fhuiry7yrh8374393939"
                value={smartContractAddress}
                readOnly
                onChange={(text) => {
                  handleSmartContractInputChange(text);
                  setIsTouched(false);
                  setMergeContractResult(null);
                  if (formError.address.isError)
                    setFormError((prev) => ({
                      ...prev,
                      address: {
                        isError: false,
                        isTouched: false,
                      },
                    }));
                }}
                pre={
                  smartContractAddress && <div className="pl-2 text-sm text-[#FFFFFF7A]">0x</div>
                }
              ></Input>
              {((isTouched && !isValid) ||
                (formError.address.isError && formError.address.isTouched)) && (
                <span
                  data-cy="invalid-address-length-error"
                  className="text-[#FF6666] text-xs mt-2"
                >
                  {/^.{40}$/.test(smartContractAddress)
                    ? 'Invalid address.'
                    : 'Invalid address length.'}
                </span>
              )}
            </div>
            <div className="flex flex-col relative flex-1">
              <label htmlFor="smart-contract-identifier" className="text-white text-sm">
                Identifier *
              </label>
              <Input
                dataCy="input-identifier-sc"
                tabIndex={0}
                classNames={{
                  base: classes(
                    'dark mt-3 placeholder:text-[#ffffff7a] text-sm p-3 border rounded-3xl shadow-[0px_8px_10px_0px_#000,0px_-2px_52px_0px_rgba(200, 200, 200, 0.06)_inset] bg-gradient-to-r from-[#ffffff0a] via-[#ffffff05] to-[#ffffff00] text-white border-white border-opacity-5',
                    isSmartContractAlreadyListed &&
                      'focus-within:border-white focus-within:border-opacity-5 focus:border-white focus:border-opacity-5'
                  ),
                  input: classes('text-white pl-2'),
                }}
                id="smart-contract-identifier"
                filter={/^[^a-zA-Z_]|[^a-zA-Z0-9_]/g}
                isError={formError.identifier.isTouched && formError.identifier.isError}
                type="text"
                value={smartContractIdentifier}
                readOnly={isSmartContractAlreadyListed}
                placeholder="Identifier for smart contract"
                onChange={(text) => {
                  const temp = [...text];
                  temp.splice(30);
                  setSmartContractIdentifier(temp.join(''));
                  setMergeContractResult(null);
                  if (formError.identifier.isError) {
                    setFormError((prev) => ({
                      ...prev,
                      identifier: {
                        isError: false,
                        isTouched: false,
                      },
                    }));
                  }
                }}
              ></Input>
              {formError.identifier.isTouched && formError.identifier.isError && (
                <span data-cy="invalid-identifier-error" className="text-[#FF6666] text-xs mt-2">
                  {identifierExistsError
                    ? 'A contract with this Identifier already exists.'
                    : !identifierRegex.test(smartContractIdentifier)
                    ? 'Invalid identifier format.'
                    : 'Identifier must be at least 4 characters long.'}
                </span>
              )}
            </div>
          </div>
          <div className="md:mt-5 mt-[14px] flex flex-col relative">
            <p className={classes('text-white text-sm')}>Select chain *</p>
            <div className="mt-3">
              <ul className={classes('flex flex-wrap items-center gap-2')}>
                {tags?.chainTags.map((tag) => {
                  return (
                    <Tag
                      tabIndex={0}
                      key={tag.slug}
                      className="select-none items-center "
                      classNames={{
                        base: 'border border-transparent focus:border-white focus:border focus:border-solid',
                      }}
                      isDarkTheme
                      value={tag}
                      isActive={selectedChain?.slug.split('-')[0] === tag.slug}
                      icon={
                        <img src={`/logos/chain/${tag.name?.toLowerCase()}.svg`} alt={tag.slug} />
                      }
                    >
                      {tag.name}
                    </Tag>
                  );
                })}
              </ul>
              {formError.chain.isTouched && formError.chain.isError && (
                <div data-cy="select-chain-error" className="text-[#FF6666] text-xs">
                  Please select a chain.
                </div>
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className={classes('text-white text-sm mb-2')}>Tag *</div>
            <InputTags
              tabIndex={0}
              placeholder="Please type tag"
              withCreate
              onFocus={() => {
                editTags.setIsFocused(true);
              }}
              readonly={isSmartContractAlreadyListed}
              limitOverflowMessage="You can only add up to 10 tags."
              limit={10}
              {...editTags}
              classNames={{
                options: 'left-0',
                base: classes(
                  'md:pl-5 pl-4',
                  isSmartContractAlreadyListed &&
                    'focus-within:border-white focus-within:border-opacity-[0.08] focus:border-white focus:border-opacity-[0.08]',
                  !formError.chain.isError &&
                    formError.tags.isTouched &&
                    formError.tags.isError &&
                    '!border-red-500'
                ),
                tags: 'max-h-[66px] min-h-[26px] overflow-y-scroll scrollbar-white',
              }}
            />
            {!formError.chain.isError && formError.tags.isTouched && formError.tags.isError && (
              <div data-cy="include-tag-error" className="text-[#FF6666] text-xs">
                Please include at least one tag other than chain tag.
              </div>
            )}
          </div>
          <div className="mt-5 flex flex-col relative">
            <label htmlFor="smart-contract-description" className={classes('text-white text-sm')}>
              <div className="flex flex-row items-center gap-1">Description *</div>
            </label>
            <Input
              tabIndex={0}
              dataCy="input-description-sc"
              type="textarea"
              className="relative mt-3 px-1.5 py-[11px] rounded-2xl resize-none"
              classNames={{
                input: 'dark resize-none h-20 scrollbar-white break-words',
                base: classes(
                  isSmartContractAlreadyListed &&
                    'focus-within:border-white focus-within:border-opacity-[0.08] focus:border-white focus:border-opacity-[0.08]'
                ),
              }}
              placeholder="Description"
              isError={formError.description.isTouched && formError.description.isError}
              readOnly={isSmartContractAlreadyListed}
              value={smartContractDescription}
              onChange={(text) => {
                if (text.length <= 273) setSmartContractDescription(text);
                if (formError.description.isError) {
                  setFormError((prev) => ({
                    ...prev,
                    description: {
                      isError: false,
                      isTouched: false,
                    },
                  }));
                }
              }}
              isDarkTheme
            >
              <div
                className={classes(
                  'absolute right-[32px] bottom-[1px] w-[23px] h-4 text-xs leading-4 text-[#666666]'
                )}
              >
                {smartContractDescription.trim().length}/273
              </div>
            </Input>
            {formError.description.isTouched && formError.description.isError && (
              <div data-cy="description-error" className="text-[#FF6666] mt-2 text-xs">
                Please ensure your input contains a minimum of 20 characters.
              </div>
            )}
          </div>
        </div>
      </div>

      <Button
        tabIndex={0}
        isDisabled={!!existErrorMsg}
        className={classes('!px-5 mt-5 mb-3 mx-8 !focus:border-2')}
        variant="darkThemeFilled"
        onClick={() => {
          setIsMergeContractError(false);
          setIsError(false);
          if (validateInputs()) {
            if (!account?.address) {
              searchParams.set('connect', 'true');
              setSearchParams(searchParams);
              return;
            }
            if (validateSmartContract()) {
              dryRunContract({
                variables: {
                  data: {
                    address: smartContractAddress,
                    chain: selectedInstance?.value || 'mainnet',
                    identifier: smartContractIdentifier,
                    network: selectedChain?.slug.split('-')[0] as string,
                  },
                  sessionKey: sessionKey as string,
                },
                onError(error) {
                  toast.error(error.message.split(':')[0]);
                  setUnsyncError(error.message.split(':')[0]);
                },
                onCompleted() {
                  onNext();
                },
              });
            }
          }
        }}
      >
        <span
          data-cy="add-functions-btn-sc"
          className="text-base text-black h-[48px] flex items-center justify-center"
        >
          Add Functions
        </span>
      </Button>
    </>
  );
};

export default ListNewSmartContract;
