import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { CheckBox, Input, Loader, MagnifyingGlassIcon } from 'components';
import { CreateSmartContractByAbi } from 'modules/ListSmartContracts/CreateSmartContractByAbi';
import { classes, removeIdentifier } from 'utils';

import { SelectFunctionsProps } from './types';
import useSelectFunctions from './useSelectFunctions';

function SelectFunctions({
  handleSelectedTab,
  tags,
  isAddFunctions,
  CloseModal,
  isSmartContractAlreadyListed,
  setIsSmartContractExist,
}: SelectFunctionsProps) {
  const {
    button,
    functionOptions,
    handleClick,
    isAllFunctionListed,
    searchString,
    selectedFunctionsSpecs,
    setSearchString,
    setSelectedFunctionsSpecs,
    smartContractIdentifier,
    isLoading,
    isDryRunContractError,
  } = useSelectFunctions({
    handleSelectedTab,
    isAddFunctions,
    isSmartContractAlreadyListed,
    setIsSmartContractExist,
    CloseModal,
  });

  if (isLoading || (!isDryRunContractError && !functionOptions)) {
    return <Loader className="h-[320px] flex text-white" />;
  }

  if (!isLoading && isDryRunContractError) {
    return (
      <CreateSmartContractByAbi handleSelectedTab={handleSelectedTab} CloseModal={CloseModal} />
    );
  }

  return (
    <>
      <div className="flex justify-between ">
        <p
          data-cy={`Smart Contract:${smartContractIdentifier}`}
          className="text-2xl w-full whitespace-pre-line text-ellipsis break-all"
        >
          Smart Contract: {smartContractIdentifier}
        </p>
      </div>
      <p data-cy="select-function-popup-sc" className="text-sm mt-2 text-[#B2B3B8]">
        Please select the functions
      </p>
      <div className="mt-6 flex flex-col relative">
        <Input
          dataCy="input-search-function-sc"
          className="relative px-5"
          classNames={{ input: 'dark' }}
          isDarkTheme
          isClearable
          placeholder="Search"
          value={searchString}
          onChange={(e) => {
            const filteredValue = e.replace(/[^a-zA-Z_]/g, ''); // Allow only letters and underscores
            setSearchString(filteredValue);
          }}
        >
          <MagnifyingGlassIcon className="absolute left-2 top-2 h-5 w-5 text-neutral-400" />
        </Input>
      </div>
      <div className="mt-4 text-[#808080] text-xs flex flex-row justify-between">
        <div>
          {selectedFunctionsSpecs.length === 0
            ? 'No selected function'
            : `${selectedFunctionsSpecs.length} selected functions`}
        </div>
        {!isAllFunctionListed && (
          <button
            className="text-white cursor-pointer"
            onClick={() => setSelectedFunctionsSpecs([])}
          >
            Clear
          </button>
        )}
      </div>
      <ul className="flex gap-4 flex-col w-[100%] max-h-[266px] !mt-2 overflow-auto scrollbar-white">
        {(searchString
          ? functionOptions?.filter(
              (fnOption) =>
                fnOption?.name?.toLowerCase().match(searchString?.toLowerCase()) ||
                fnOption?.identifier?.toLowerCase().match(searchString?.toLowerCase())
            )
          : functionOptions
        )?.map((functionOption) => {
          const isActive = selectedFunctionsSpecs.includes(
            removeIdentifier(functionOption?.identifier, smartContractIdentifier) as string
          );
          const name = removeIdentifier(functionOption?.identifier, smartContractIdentifier);
          const isListed = functionOption?.status === 'Untouched';
          return (
            <li className="w-full" key={`collection-${functionOption?.identifier}`}>
              <button
                className={classes(
                  'flex items-center py-1 px-4 text-sm relative w-full rounded-2xl border border-[#ffffff1f] hover:bg-[#ffffff0d] hover:border-[#ffffff0d] flex-row-reverse ',
                  isActive && 'bg-[#ffffff0d] border-[#ffffff0d]',
                  isListed && 'bg-[#ffffff0d] cursor-not-allowed opacity-60'
                )}
                disabled={isListed}
                onContextMenu={() => handleClick(name)}
                onClick={() => handleClick(name)}
              >
                <div className="w-full flex gap-2 items-center">
                  <Cog6ToothIcon className="h-6 w-6 shrink-0" color="grey" />
                  <div
                    data-cy={`${name}`}
                    className="flex-1 my-1 break-words"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {name}(
                    {(functionOption?.inputs as { value: string }[])
                      .map(({ value }) => value)
                      .join(', ')}
                    )
                    <p className="text-[#ffffff80] text-xs">
                      (
                      {(functionOption?.outputs as { name: string; value: string }[])
                        .map(({ name, value }) => `${name}:${value}`)
                        .join(', ')}
                      )
                    </p>
                  </div>
                </div>
                <div className="w-8 ml-2">
                  <CheckBox isChecked={isActive || isListed} isDarkTheme />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
      {button}
    </>
  );
}

export default SelectFunctions;
