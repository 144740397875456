import { useCallback, useMemo } from 'react';

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { useApi } from 'contexts';
import { useDidOnboarding } from 'hooks';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { HTMLAttributes, Setter } from 'types';
import { classes } from 'utils';

import { Modal } from '../Modal';

import ConnectWalletHeader from './components/ConnectWalletHeader';
import ConnectWalletLoading from './components/ConnectWalletLoading';
import DefaultWalletList from './components/DefaultWalletList';
import ErrorRefresh from './components/ErrorRefresh/ErrorRefresh';
import SelectedWalletAccounts from './components/SelectedWalletAccounts';

interface Props extends HTMLAttributes<HTMLDivElement> {
  options?: InjectedAccountWithMeta[] | null;
  walletOption?: InjectedAccountWithMeta[] | null;
  selected: InjectedAccountWithMeta | null;
  setSelected: Setter<InjectedAccountWithMeta | null>;
  showRefresh: boolean;
}

export function ConnectAccount({ selected, setSelected, walletOption, showRefresh }: Props) {
  const {
    isConnecting,
    onConfirm,
    selectedWallet,
    setSelectedWallet,
    setShowRefresh,
    isConfirming,
    setIsConfirming,
  } = useApi();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // const doesHaveOptions = hasOptions(options);
  const [didOnboarding] = useDidOnboarding();
  const [_, redirect] = useMemo(
    () => [searchParams.get('connect') !== null, searchParams.get('redirect')],
    [searchParams]
  );
  const [cookies] = useCookies(['didOnboarding']);

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        searchParams.set('connect', '');
      } else {
        searchParams.delete('connect');
        searchParams.delete('redirect');
      }
      setSearchParams(searchParams);
      setShowRefresh(false);
      setIsConfirming(false);
      setSelectedWallet('');
    },
    [searchParams, setSearchParams]
  );

  const onSubmit = useCallback(
    (selected: InjectedAccountWithMeta) => {
      onConfirm(selected, (account) => {
        setIsOpen(false);
        if (!cookies?.didOnboarding || !cookies?.didOnboarding[account.address]) {
          navigate({
            pathname: routes.onboarding,
            search: redirect ? `?redirect=${redirect}` : undefined,
          });
        } else {
          redirect && navigate(redirect);
        }
      });
    },
    [didOnboarding, navigate, onConfirm, redirect, selected, setIsOpen]
  );

  return (
    <Modal
      isOpen={searchParams.get('connect') !== null}
      setIsOpen={setIsOpen}
      className={classes('w-[440px] max-h-[90%] overflow-auto z-50', isConnecting && 'p-0')}
      isDarkTheme
      isConnectWallet
      classNames={{
        dialog: 'z-[99]',
      }}
    >
      {showRefresh && (
        // Model for showing the "something went wrong message and refresh button"
        <ErrorRefresh />
      )}
      {!showRefresh && (
        <div className="w-full">
          {/* Different Header for Different steps of connect wallet */}
          <ConnectWalletHeader />

          {/* Loading until wallet connects */}
          {(isConnecting || isConfirming) && <ConnectWalletLoading />}

          {/* Show wallets list when nothing is loading */}
          {!(isConnecting || isConfirming) && (
            <div className="w-full mt-6 flex-1 overflow-y-auto">
              {selectedWallet ? (
                <SelectedWalletAccounts onSubmit={onSubmit} walletOption={walletOption} />
              ) : (
                <DefaultWalletList />
              )}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
