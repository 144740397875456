import { BreadCrumbs, Skeleton } from 'components';
import { useSmartContract } from 'contexts';
import { useBreadCrumbsChild } from 'hooks/useBreadCrumbs';
import { useParams } from 'react-router-dom';

import { CollectionsContract } from './Components/SCDetailsTab/CollectionsContract';
import { SmartContractDetails } from './Components/SmartContractDetails/SmartContractDetails';

const SmartContract = () => {
  const { id: address, network } = useParams<{ id?: string; network?: string }>();
  useBreadCrumbsChild({ page: 'SmartContract', params: [address, network] }, [{ page: 'Library' }]);

  const { resource: smartContract } = useSmartContract();

  return (
    <Skeleton.Provider isLoading={!smartContract}>
      <div className="mb-20 lg:p-16 !pt-0 px-0  w-full justify-start">
        <BreadCrumbs />
        <div className="md:[&>*]:mb-16 [&>*]:mb-12 ">
          <SmartContractDetails />
          <CollectionsContract key={address} />
        </div>
      </div>
    </Skeleton.Provider>
  );
};

export default SmartContract;
