import { ApiPromise, WsProvider } from '@polkadot/api';
import '@polkadot/api-augment/substrate';
import { TypeRegistry } from '@polkadot/types/create';
import { BigNumber } from 'bignumber.js';
import { ChainProperties, ChainType, InjectedAccountWithMeta, Setter } from 'types';

import { web3FromSource } from './substrate';

export { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp';
export { hexToU8a, stringToHex, u8aToHex, u8aToString, u8aWrapBytes } from '@polkadot/util';

export { ApiPromise, WsProvider };

const registry = new TypeRegistry();

export async function getChainProperties(api: ApiPromise): Promise<ChainProperties> {
  const [chainProperties, systemName, systemVersion, systemChain, systemChainType] =
    await Promise.all([
      api.rpc.system.properties(),
      api.rpc.system.name(),
      api.rpc.system.version(),
      (await api.rpc.system.chain()).toString(),
      api.rpc.system.chainType
        ? api.rpc.system.chainType()
        : Promise.resolve(registry.createType('ChainType', 'Live')),
      api.rpc.system,
    ]);

  const result = {
    genesisHash: api.genesisHash.toHex(),
    systemName: systemName.toString(),
    systemVersion: systemVersion.toString(),
    systemChainType: systemChainType as ChainType,
    systemChain,
    tokenDecimals: chainProperties.tokenDecimals.isSome
      ? chainProperties.tokenDecimals.unwrap().toArray()[0].toNumber()
      : 10,
    tokenSymbol: chainProperties.tokenSymbol.isSome
      ? chainProperties.tokenSymbol
          .unwrap()
          .toArray()
          .map((s) => s.toString())[0]
      : 'Unit',
  };

  return result;
}

export async function depositAmount(
  api: ApiPromise,
  account: InjectedAccountWithMeta,
  amount: number,
  tokenDecimals: number,
  cb: (status: string, hex: string) => void,
  setDepositLoading?: Setter<boolean>,
  setError?: Setter<string | undefined>
) {
  if (account && (await api.isReady)) {
    const value = new BigNumber(amount).multipliedBy(10 ** tokenDecimals).toNumber();

    const injector = await web3FromSource(account.meta.source);
    try {
      await api.tx.timegraph
        .deposit(value)
        .signAndSend(
          account.address,
          { signer: injector?.signer, nonce: -1 },
          ({ events = [], status }) => {
            if (status.isInBlock) {
              cb('isInBlock', status.asInBlock.toHex());
            } else if (status.isFinalized) {
              cb('isFinalized', status.asFinalized.toHex());
            }
            if (status.asInBlock || status.asFinalized) {
              events
                .filter(({ event }) => api.events.system.ExtrinsicFailed.is(event))
                .forEach(
                  ({
                    event: {
                      data: [error],
                    },
                  }) => {
                    cb('Error', `${error.toString()}`);
                  }
                );
            }
          }
        );
    } catch (e) {
      console.log('error In deposit', e);
      setError!((e as string).toString());
      // cb('Error', (e as string).toString());
      setDepositLoading && setDepositLoading(false);
    }
  }
}

export async function getTransactionGasCost(
  api: ApiPromise,
  account: InjectedAccountWithMeta,
  amount: number
) {
  const info = await api.tx.timegraph.deposit(amount).paymentInfo(account.address);
  return info.partialFee;
}

export async function withdrawAmount(
  api: ApiPromise,
  account: InjectedAccountWithMeta,
  amount: number,
  tokenDecimals: number,
  cb: (status: string, hex: string) => void,
  setWithdrawLoading?: Setter<boolean>,
  setError?: Setter<string>
) {
  if (account && (await api.isReady)) {
    const value = new BigNumber(amount).multipliedBy(10 ** tokenDecimals).toString();

    const injector = await web3FromSource(account.meta.source);

    try {
      await api.tx.timegraph
        .withdraw(value)
        .signAndSend(
          account.address,
          { signer: injector?.signer, nonce: -1 },
          ({ events = [], status }) => {
            if (status.isInBlock) {
              cb('isInBlock', status.asInBlock.toHex());
            } else if (status.isFinalized) {
              cb('isFinalized', status.asFinalized.toHex());
            }
            if (status.asInBlock || status.asFinalized) {
              events
                .filter(({ event }) => api.events.system.ExtrinsicFailed.is(event))
                .forEach(
                  ({
                    event: {
                      data: [error],
                    },
                  }) => {
                    cb('Error', `${error.toString()}`);
                  }
                );
            }
          }
        );
    } catch (e) {
      setError!((e as string).toString());
      setWithdrawLoading && setWithdrawLoading(false);
    }
  }
}

export async function getWithdrawTransactionGasCost(
  api: ApiPromise,
  account: InjectedAccountWithMeta,
  amount: number
) {
  const info = await api.tx.timegraph.withdraw(amount).paymentInfo(account.address);
  return info.partialFee;
}

/*
usage
const {api, account, chainProps: { tokenDecimals } } = useApi();   ,
const value = new BigNumber(0.01).multipliedBy(10 ** tokenDecimals).toNumber();
const results = await depositAmount(api, account, value);
*/
